<template>
  <div class="comment">
    <span v-if="showComment1">{{comment1}}</span>
    <v-divider
      v-if="showComment1 && showComment2"
      class="my-4"
    />
    <span v-if="showComment2">{{comment2}}</span>
  </div>
</template>

<script>

import DocumentEditor from '@/mixins/document-editor'

export default {
  mixins: [DocumentEditor],
  deactivated () {
    this.$removeFromKeepAliveCache()
    this.$destroy()
  },
  computed: {
    cacheType () {
      return this.$cacheTypeDetailFromString(this.documentType)
    },
    id () {
      return this.documentId
    },
    showComment1 () {
      return this.comment1 && this.comment1 !== ''
    },
    showComment2 () {
      return this.comment2 && this.comment2 !== ''
    },
    comment1 () {
      switch (this.documentType) {
        case 'invoices':
          return this.commentColumnName === 'externalStatusComment' ? this.document?.externalStatusComment : this.document?.comment
        case 'collaboration-tasks':
        case 'disputes':
        case 'dispute-invoices':
        case 'promises':
        case 'promise-invoices':
          return this.document?.description
        default:
          return this.document?.comment
      }
    },
    comment2 () {
      switch (this.documentType) {
        case 'collaboration-tasks':
        case 'disputes':
        case 'dispute-invoices':
        case 'promises':
        case 'promise-invoices':
          return this.document?.custom // + (this.document?.description && this.document?.custom ? '\n- - - - - - - - - -\n' : '') + (this.document?.custom ? this.document.custom : '')
        default:
          return null
      }
    }
  },
  methods: {
  },
  props: {
    documentId: String,
    documentType: String,
    commentColumnName: String
  }
}
</script>
<style lang="stylus" scoped>
.comment
  white-space pre-line
</style>
